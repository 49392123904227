@charset "UTF-8";

.site-content {
  font-size: 1.125em;
  line-height: 1.6em;
}

figure img,
.author-avatar img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

figure {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  text-align: center;
}

article {
  display: block;
}

.hentry {
  margin: 0 0 1.5em;
  padding-bottom: 2em;
}

.site-main {
  margin: 1.4em;
  font-size: .8em;
  line-height: 1.6em;
}

.wp-block-image img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.featured-image img {
  height: auto;
  max-width: 100%;
}

@media screen and (min-width: 65em) and (max-width: 70em) {
  .sidebar-right .entry-title,
  .sidebar-left .entry-title {
    padding-right: 1em;
    padding-left: 1em;
  }
}

.entry-title.index-excerpt {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

@media screen and (min-width: 30em) {
  .entry-title.index-excerpt {
    margin-top: 1.5em;
    font-size: 2em;
  }
}

@media screen and (min-width: 30em) {
  .site-main {
    margin: 1.8em;
  }
}

@media screen and (min-width: 40em) {
  .site-main {
    font-size: 1em;
  }

  .entry-title {
    font-weight: bold;
    margin-top: 1.5em;
    font-size: 2.6em;
    line-height: 1.3em;
  }
}

@media screen and (min-width: 57em) {
  .site-main {
    max-width: 45em;
    margin: 1.8em auto;
  }
}

@media screen and (min-width: 50em) {
  .deck,
  .entry-content {
    padding: 0 3em;
  }
}

.entry-title {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 2em;
  line-height: 1.3em;
  text-align: center;
  color: #000;
  word-wrap: break-word;
  /* Catch and wrap very long words on small screens */
}

.entry-title a {
  color: inherit;
  text-decoration: none;
}

.entry-title a:hover,
.entry-title a:focus {
  text-decoration: underline;
}

.content-area {
  overflow-x: hidden;
}

.page-content,
.deck,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0;
}

article h1,
h2,
.site-content h3,
.site-content h4,
.site-content h5,
.site-content h6 {
  font-family: 'Fira Sans', sans-serif;
  line-height: 1.3em;
  clear: both;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

article h1 {
  font-size: 2.4em;
}

h2 {
  font-size: 2.2em;
}

.site-content h3 {
  font-size: 2em;
}

.site-content h4 {
  font-size: 1.8em;
}

.site-content h5 {
  font-size: 1.6em;
}

.site-content h6 {
  font-size: 1.4em;
}

.site-content p {
  margin-bottom: 1.6em;
}

.page-content a,
.page-content a,
.entry-content a,
.entry-content a,
.entry-summary a,
.entry-summary a,
.comment-content a,
.comment-content a {
  text-decoration: none;
  border-bottom: 2px solid #c3c3c3;
}

.page-content a:hover,
.page-content a:focus,
.entry-content a:hover,
.entry-content a:focus,
.entry-summary a:hover,
.entry-summary a:focus,
.comment-content a:hover,
.comment-content a:focus {
  border-bottom: 3px solid #c3c3c3;
  box-shadow: inset 0 -3px 0 #c3c3c3;
}

.site-content a {
  color: #000;
}

.site-content a:visited {
  color: #000;
}

a:hover,
a:focus,
a:active {
  color: black;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.entry-meta a {
  font-weight: 700;
}

.entry-meta {
  margin: 0 -1.473684210526316em;
  padding: 2em 1.473684210526316em;
  font-family: "Fira Sans", sans-serif;
  font-size: 95%;
  font-style: italic;
  text-align: center;
  background: #eee;
}

@media screen and (min-width: 40em) {
  .entry-title {
    margin-top: 1.5em;
    font-size: 2.8em;
    line-height: 1.3em;
  }
}

@media screen and (min-width: 30em) {
  .entry-meta {
    margin: 0 -1.894736842105263em;
  }
}

/* Conditions for no-sidebar layout */

@media screen and (min-width: 57em) {
  .entry-meta {
    position: relative;
    width: 100%;
    width: 100vw;
    left: 0;
    left: calc((-100vw + 51.2em) / 2);
    overflow: hidden;
  }
}

/* Conditions for sidebar-right layout */

@media screen and (min-width: 65em) {
  .sidebar-right .entry-meta {
    width: 100%;
    width: calc(100vw - 306px);
    left: 0;
    margin: 0;
  }
}

@media screen and (min-width: 70.75em) {
  .sidebar-right .entry-meta {
    left: calc((-100vw + 65.3em) / 2);
  }
}

/* Conditions for sidebar-left layout */

@media screen and (min-width: 65em) {
  .sidebar-left .entry-meta {
    width: 100%;
    width: calc(100vw - 306px);
    left: 0;
    right: 0;
    margin: 0;
  }
}

@media screen and (min-width: 70.75em) {
  .sidebar-left .entry-meta {
    left: calc((-100vw + 65.3em) / 2);
  }
}

.author-avatar {
  height: 50px;
  width: 50px;
  margin: 0 auto 1em;
}

.has-small-font-size {
  font-size: 13px !important;
}

.has-text-align-center {
  text-align: center;
}

@media screen and (min-width: 50em) {
  .has-avatar {
    display: inline-block;
    text-align: left;
    width: 20em;
  }

  .author-avatar {
    height: 70px;
    width: 70px;
    float: left;
    margin-right: 1.5em;
    margin-bottom: 0;
  }
}

.author-avatar img {
  border-radius: 50px;
}

.byline,
.posted-on,
.entry-meta .comments-link {
  display: block;
  padding: 0;
}

.single .byline,
.group-5 .byline {
  display: inline;
}

.entry-content.index-excerpt {
  margin-top: 0.3em;
}

.continue-reading {
  text-align: center;
}

.continue-reading a,
.entry-content .continue-reading a {
  display: inline-block;
  margin: 1em auto;
  padding: 1em 2em;
  font-family: "Fira Sans", sans-serif;
  text-decoration: none;
  border: 1px solid #c3c3c3;
}

.continue-reading::after {
  display: block;
  content: "";
  width: 7em;
  border-bottom: 1px solid #c3c3c3;
  margin: 4em auto 0;
}

.continue-reading a::after {
  content: "\2026";
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  border: 0;
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.entry-header .index-entry-meta {
  font-size: .8em;
}

.entry-footer,
.index-entry-meta {
  font-family: "Fira Sans", sans-serif;
  font-size: 95%;
  font-style: italic;
  text-align: center;
}

@media screen and (min-width: 30em) {
  .index-entry-meta {
    font-size: 1em;
  }
}

@media screen and (min-width: 30em) {
  .index-entry-meta .byline,
  .index-entry-meta .posted-on,
  .index-entry-meta .comments-link {
    display: inline-block;
  }
}

.byline,
.posted-on,
.entry-meta .comments-link {
  display: block;
  padding: 0;
}

@media screen and (min-width: 30em) {
  .index-entry-meta .byline::after,
  .index-entry-meta .comments-link::before {
    content: "|";
    padding: 0 1em;
  }
}

.entry-footer span a,
.index-entry-meta a {
  font-weight: 700;
  text-decoration: none;
  color: #000;
}

.index-excerpt p {
  margin-top: 1em;
}

.vcard {
  font-weight: bold;
}

.published {
  font-weight: bold;
}

.comment-navigation,
.posts-navigation,
.post-navigation,
.paging-navigation {
  padding: 2em 0;
  text-align: left;
  overflow: hidden;
}

.post-navigation:before {
  display: block;
  content: "";
  width: 7em;
  border-bottom: 1px solid #c3c3c3;
  margin: 2em auto;
}

.navigation .nav-links,
.paging-navigation .nav-links {
  max-width: 45em;
  margin: 0 auto;
  padding: 0 0.4em;
}

.navigation .nav-links .meta-nav,
.paging-navigation .nav-links .meta-nav {
  display: block !important;
  font-size: 0.9em;
}

.navigation .nav-links .post-title,
.paging-navigation .nav-links .post-title {
  font-size: 0.9em;
  font-weight: 700;
}

.navigation .nav-links a:hover .post-title,
.navigation .nav-links a:focus .post-title,
.paging-navigation .nav-links a:hover .post-title,
.paging-navigation .nav-links a:focus .post-title {
  text-decoration: underline;
}

.navigation .nav-links .nav-previous,
.paging-navigation .nav-links .nav-previous {
  padding-bottom: 1em;
}

.navigation .nav-links a,
.paging-navigation .nav-links a {
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 1em;
  border: 1px solid #c3c3c3;
}

@media screen and (min-width: 50em) {
  .navigation .nav-links,
  .paging-navigation .nav-links {
    padding: 2em 3em;
  }

  .navigation .nav-links .meta-nav,
  .paging-navigation .nav-links .meta-nav {
    font-size: 1.2em;
    padding-bottom: 1em;
  }

  .navigation .nav-links .post-title,
  .paging-navigation .nav-links .post-title {
    font-size: 1.2em;
  }

  .navigation .nav-links .nav-next,
  .paging-navigation .nav-links .nav-next {
    display: flex;
    align-items: stretch;
    float: right;
    text-align: right;
    width: 48%;
  }

  .navigation .nav-links .nav-previous,
  .paging-navigation .nav-links .nav-previous {
    display: flex;
    align-items: stretch;
    float: left;
    width: 48%;
  }

  .navigation .nav-links .nav-links,
  .paging-navigation .nav-links .nav-links {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
  }
}

.paging-navigation {
  text-align: center;
}

.paging-navigation ul {
  display: inline-block;
  padding: 0;
  list-style-type: none;
}

.paging-navigation li {
  line-height: 1.6em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: inline-block;
}

.paging-navigation li:last-child {
  margin-bottom: 0;
}

.paging-navigation .current {
  padding: 0.8em 1.1em;
  font-weight: 700;
}

.paging-navigation li a {
  padding: 0.4em 1.2em;
}

.paging-navigation a {
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 1em;
  border: 1px solid #c3c3c3;
}

.paging-navigation a:hover {
  border-color: #000;
}


